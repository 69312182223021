import { useEffect, useReducer, useState } from 'react';
import './App.css';
import logo from "./images/bokLOGO100.png"
import { waitFor } from '@testing-library/react';
import pop from "./sounds/pop.mp3"
import lose from "./sounds/lose.mp3"
import win from "./sounds/win.mp3"
import useSound from 'use-sound';

function App() {
  let [score,setScore] = useState(0)

  
  let [tileArray, setTileArray] = useState([
    <Tile/>,<Tile/>,<Tile/>,
    <Tile/>,<Tile/>,<Tile/>,
    <Tile/>,<Tile/>,<Tile/>,
    <Tile/>,<Tile/>,<Tile/>,
    <Tile/>,<Tile/>,<Tile/>,
    <Tile/>,<Tile/>,<Tile/>,
    <Tile/>,<Tile/>
  ])

  function placeBomb(){
    let randomNumber = []
    while(randomNumber.length < bombCount){
      let r = Number((Math.random() * 19).toFixed())
      if(randomNumber.indexOf(r) === -1) randomNumber.push(r)
    }
    for(let i = 0;i<bombCount;i++){
      tileArray[randomNumber[i]] = <Bomb></Bomb>
    }
    forceUpdate()
  }

  async function handleScore(e,index){
    let clickedTile = e.target.id
    if(clickedTile === "Tile"){
      if(e.target.style.background !== "none"){
        popSound()
        visible[index] = false
        setScore(score+1)
        if(score=== 19-bombCount){
          winSound()
          setPopupMessage("You Won! Congratulations!")
          setIsPopupOpen(true)
        }
      } 
    }else if(clickedTile === "") return
    else{
      loseSound()
      setPopupMessage("You Lost!")
      setIsPopupOpen(true)
    }
  }

  function restartGame(){
    visible = [true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true]
    setVisible(visible)
    tileArray = [
      <Tile/>,<Tile/>,<Tile/>,
      <Tile/>,<Tile/>,<Tile/>,
      <Tile/>,<Tile/>,<Tile/>,
      <Tile/>,<Tile/>,<Tile/>,
      <Tile/>,<Tile/>,<Tile/>,
      <Tile/>,<Tile/>,<Tile/>,
      <Tile/>,<Tile/>
    ]
    setTileArray(tileArray)
    score=0
    setScore(score)
    forceUpdate()
    placeBomb()
  }

  useEffect(() => {
    placeBomb()
    forceUpdate()
  },[])

  let [bombCount, setBombCount] = useState(1)
  let [visible, setVisible] = useState([true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true])
  let [isPopupOpen, setIsPopupOpen] = useState(false)
  let [popupMessage, setPopupMessage] = useState("You lost!")

  const [,forceUpdate] = useReducer(x => x+1,0)
  const [popSound] = useSound(pop,{volume:0.05})
  const [loseSound] = useSound(lose,{volume:0.15})
  const [winSound] = useSound(win,{volume:0.10})
  
  return (
    <div className="App">

        {
          isPopupOpen && (<div id='Popup'>
            <div id='PopupContainer'>
              <h1>{popupMessage}</h1>
              <button onClick={e=>{
                restartGame()
                setIsPopupOpen(false)
              }}>Start New Game</button>
            </div>
          </div>)
        }

      
      
      <div id='Header'>
        <a href="https://bonklana.com"><img src={logo} alt="logo" style={{width:"100px"}}/></a>
        <h1>Bonklana</h1>

      </div>
      
      <h2>$BOK Tiles v0.1</h2>
      <h3>Score: {score} </h3>
      <div id='TileContainer'>
        {
          tileArray.map((value,index) => {
            return(
                <div key={index}
                style={{width:"100px", height:"100px",border:"1px solid #00000030",borderRadius:"10px"}}>
                  {
                    visible[index] && (<div key={index} onClick={e=>handleScore(e,index)}>{value}</div>)
                  }
                </div>
            )
          })
        }
      </div>
      <h2>Bomb Count: {bombCount}</h2>
      <input type="range" min={1} max={5} step={1} value={bombCount} onChange={e => {
        bombCount = e.target.value
        setBombCount(e.target.value)
        restartGame()
        }} id='BombSlider'/>
      <h2>How to play?</h2>
      <p>There are 20 tiles on the board.</p>
      <p>One of the tiles contain a dangerous trap placed by the Market Maker.</p>
      <p>Clear out the safu tiles to win the game!</p>
      <p>But if you step on the trap, you lose! Can you survive?</p>
      
    </div>
  );
}

function Tile(){
  
        
  return(
  <div id='Tile' ></div>
  )
}

function Bomb(){
  return(
    <div id='Bomb'></div>
  )
}

export default App;
